.header  {
    width: 100%;
    height: auto;
    nav  {
        .nav-brand {
            padding-top:10px;
            padding-bottom: 10px;
            padding-left: 40px;
            a {
            .nav-logo {
                width: 145px;
                height: 45px;
                background:url('../../assets/loggo.svg');
                border: none;

            }
        }
        }
        
    }
}